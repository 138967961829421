<template>
  <div>
      <section>
          <button
              v-if="$can('EDIT', 'worker-mitra')"
              class="uk-button uk-button-default uk-border-rounded uk-margin-right"
              style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
              @click="$emit('action', true)"
          >Edit</button>
          <button
              v-if="$can('EDIT', 'worker-mitra')"
              class="uk-button uk-button-primary uk-border-rounded uk-margin-right"
              style="color: #ffffff"
              @click="docValidating = true"
          >
              <img :src="`${images}/verif-white.svg`" alt="" style="width:20px" />
              Mutiple Validation
          </button>
      </section>
      <section class="uk-margin">
        <div class="uk-flex uk-flex-middle">
          <img class="profile-photo" :src="data.photo_url ? data.photo_url.file_url : 'https://getuikit.com/docs/images/photo.jpg'" />
          <div class="uk-margin-left uk-text-bold">{{ data.fullname }}</div>
        </div>
      </section>
      <section class="uk-margin">
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
          v-for="(detail, dKey) in details"
          :key="dKey"
        >
          <div class="uk-width-1-2">{{ detail.label }}</div>
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <div class="uk-width-auto">:&nbsp;</div>
              <div v-if="['birthdate'].includes(detail.value)" class="uk-width-expand">
                {{ data[detail.value] ? formatter.dateFormatSlash(data[detail.value]) : '' }}
              </div>
              <div v-else-if="['identity_expired_date'].includes(detail.value)" class="uk-width-expand">
                {{ checkExpirateKTP(data[detail.value]) }}
              </div>
              <div v-else-if="['religion', 'blood_type'].includes(detail.value)" class="uk-width-expand uk-text-capitalize">
                {{ data[detail.value] }}
              </div>
              <div v-else-if="['gender', 'marital_status'].includes(detail.value)" class="uk-width-expand">
                {{ getRealData(detail.value, data[detail.value]) }}
              </div>
              <div v-else>{{ data[detail.value] }}</div>
            </div>
          </div>
        </div>
      </section>

      <ValidateMultipleDocument
        v-if="docValidating"
        :user-id="$route.params.mitra_id"
        @close="docValidating = false"
      />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    components: {
        ValidateMultipleDocument: () => import('@/components/globals/modals/ValidateMultipleDocument.vue'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false, 
            details: [
                { label: 'Email Address', value: 'email' },
                { label: 'Nama Lengkap Sesuai KTP', value: 'fullname_ktp' },
                { label: 'Nomor Induk Kependudukan (Sesuai KTP)', value: 'identity_number' },
                { label: 'Nomor Whatsapp', value: 'whatsapp_number' },
                { label: 'Nomor Telepon', value: 'phone_number' },
                { label: 'Tempat Lahir', value: 'birthplace_city' },
                { label: 'Tanggal Lahir Sesuai KTP', value: 'birthdate' },
                { label: 'Masa Berlaku KTP', value: 'identity_expired_date' },
                { label: 'Jenis Kelamin', value: 'gender' },
                { label: 'Agama', value: 'religion' },
                { label: 'Golongan Darah', value: 'blood_type' },
                { label: 'Status Pernikahan', value: 'marital_status' },
                { label: 'Jumlah Anak', value: 'children_amount' },
                { label: 'Alamat lengkap sesuai KTP', value: 'address' },
                { label: 'Alamat lengkap domisili', value: 'domicile_address' },
            ],
            data: null,
            formatter: formatter,
            docValidating: false,
        };
    },

    async mounted() {
        this.getMitraProfile();
    },

    methods: {
        ...mapActions({
            getProfile: 'mitra/getProfile',
        }),

        getRealData(variable, value) {
            if (variable === "gender") {
                switch (value) {
                case "male":
                    return "Laki-laki";
                case "female":
                    return "Perempuan";
                default:
                    return '-';
                }
            } else {
                switch (value) {
                case "married":
                    return "Kawin";
                default:
                    return "Belum Kawin";
                }
            }
        },

        checkExpirateKTP(value) {
            if (this.data.identity_valid_forever) {
                return "Selamanya";
            } else if (!this.data.identity_valid_forever && value && value !== "") {
                return this.formatter.dateFormatSlash(value);
            } else {
                return "";
            }
        },

        async getMitraProfile() {
            this.isLoading = true;

            const data = await this.getProfile(this.$route.params.mitra_id);

            if (data.result) {
                this.data = { ...data.result, ...data.result.detail.profile };
                this.$emit("passData", this.data);
            }

            this.isLoading = false;
        },
    }
};
</script>

<style scoped></style>
