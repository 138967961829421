<template>
  <div>
    <section>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="saveData"
        >
            Save
        </button>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="$emit('action', false)"
        >
            Cancel
        </button>
      </section>
      <section class="uk-margin">
        <div class="uk-flex uk-flex-middle">
          <img class="profile-photo" :src="data.photo_url ? data.photo_url.file_url : 'https://getuikit.com/docs/images/photo.jpg'" />
          <div class="uk-margin-left uk-text-bold">{{ data.fullname }}</div>
        </div>
      </section>
      <section class="uk-margin">
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
          v-for="(detail, dKey) in details"
          :key="dKey"
        >
          <div class="uk-width-1-2 uk-margin-auto-vertical">{{ detail.label }}</div>
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <!-- SELECT INPUT -->
              <div v-if="detail.type === 'select'" class="uk-width-expand">
                <!-- LIVE SEARCH -->
                <div v-if="detail.value === 'birthplace_city_id'">
                    <multiselect
                      v-model="form[detail.value]"
                      placeholder="Type to search city"
                      label="name"
                      track-by="name"
                      :options="cities"
                      :loading='isLoading'
                      :searchable="true"
                      @search-change="getCityOptions"
                  ></multiselect>
                </div>
                <!-- STATIC SEARCH -->
                <select v-else v-model="form[detail.value]" class="uk-select">
                  <option v-for="option in detail.option" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <!-- DATE INPUT -->
              <div v-else-if="detail.type === 'date'" class="uk-width-expand">
                <section v-if="detail.value === 'identity_expired_date'" >
                    <!-- DATE INPUT WITH CHECKBOX -->
                    <div class="uk-flex uk-flex-middle">
                        <datetime
                            class="uk-margin-small-right"
                            name="date"
                            id="date"
                            input-class="uk-input uk-border-rounded"
                            type="date"
                            format="dd LLL yyyy"
                            :max-datetime="detail.value === 'birthdate' ? datetime.now().toISODate() : null"
                            :disabled="form.identity_valid_forever"
                            v-model="form[detail.value]"
                        ></datetime>
                        <label><input v-model="form.identity_valid_forever" class="uk-checkbox" type="checkbox" @change="form[detail.value] = null"> KTP Berlaku selamanya</label>
                    </div>
                </section>
                <section v-else>
                    <datetime
                        name="date"
                        id="date"
                        input-class="uk-input uk-border-rounded"
                        type="date"
                        format="dd LLL yyyy"
                        :max-datetime="detail.value === 'birthdate' ? datetime.now().toISODate() : null"
                        v-model="form[detail.value]"
                    ></datetime>
                </section>
              </div>
              <!-- TEXT INPUT -->
              <div v-else class="uk-width-expand">
                <input
                  v-model="form[detail.value]"
                  :disabled="['email', 'phone_number'].includes(detail.value)"
                  class="uk-input"
                  type="text"
                >
              </div>
            </div>
            <div v-if="errorsD[detail.value]" class="uk-text-danger">{{ errorsD[detail.value] }}</div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import { notificationSuccess } from '@/utils/notification';
import Multiselect from 'vue-multiselect';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    components: { Datetime, Multiselect },
    data() {
        return {
            datetime: DateTime,
            isLoading: false,
            details: [
                { label: 'Email Address', value: 'email', type: "text" },
                { label: 'Nama Lengkap Sesuai KTP', value: 'fullname_ktp', type: "text" },
                { label: 'Nomor Induk Kependudukan (Sesuai KTP)', value: 'identity_number', type: "text" },
                { label: 'Nomor Whatsapp', value: 'whatsapp_number', type: "text" },
                { label: 'Nomor Telepon', value: 'phone_number', type: "text" },
                { label: 'Tempat Lahir', value: 'birthplace_city_id', type: "select" },
                { label: 'Tanggal Lahir Sesuai KTP', value: 'birthdate', type: "date" },
                { label: 'Masa Berlaku KTP', value: 'identity_expired_date', type: "date" },
                {
                    label: 'Jenis Kelamin',
                    value: 'gender',
                    type: "select",
                    option: [
                        { value: null, text: "Pilih jenis kelamin:" },
                        { value: "male", text: "Laki-laki" },
                        { value: "female", text: "Perempuan" }
                    ]
                },
                {
                    label: 'Agama',
                    value: 'religion',
                    type: "select",
                    option: [
                        { value: null, text: "Pilih agama:" },
                        { value: "islam", text: "Islam" },
                        { value: "protestan", text: "Protestan" },
                        { value: "katolik", text: "Katolik" },
                        { value: "hindu", text: "Hindu" },
                        { value: "buddha", text: "Buddha" }
                    ]
                },
                {
                    label: 'Golongan Darah',
                    value: 'blood_type',
                    type: "select",
                    option: [
                        { value: null, text: "Pilih golongan darah:" },
                        { value: "a", text: "A" },
                        { value: "b", text: "B" },
                        { value: "ab", text: "AB" },
                        { value: "o", text: "O" }
                    ]
                },
                {
                    label: 'Status Pernikahan',
                    value: 'marital_status',
                    type: "select",
                    option: [
                        { value: null, text: "Pilih status pernikahan:" },
                        { value: "married", text: "Kawin" },
                        { value: "single", text: "Belum Kawin" }
                    ]
                },
                { label: 'Jumlah Anak', value: 'children_amount', type: "text" },
                { label: 'Alamat lengkap sesuai KTP', value: 'address', type: "text" },
                { label: 'Alamat lengkap domisili', value: 'domicile_address', type: "text" },
            ],
            cities: [
                { name: this.$props.data.birthplace_city, _id: this.$props.data.birthplace_city_id }
            ],
            form: { ...this.$props.data, birthplace_city_id: { name: this.$props.data.birthplace_city, _id: this.$props.data.birthplace_city_id } },
            errorsD: {},
        };
    },

    methods: {
        ...mapActions({
            patchProfile: 'mitra/patchProfile',
            getCities: 'option_data/getCities',
        }),

        async saveData() {
            this.isLoading = true;

            if (!this.validateData()) {
                this.isLoading = false;
                return false;
            }

            const nForm = {
                "identity_number": this.form.identity_number,
                "birthplace_city_id": this.form.birthplace_city_id._id,
                "birthdate": this.form.birthdate,
                "gender": this.form.gender,
                "address": this.form.address,
                "detail": {
                    "profile": {
                        "fullname_ktp": !this.form.fullname_ktp || this.form.fullname_ktp === "" ? null : this.form.fullname_ktp,
                        "whatsapp_number": !this.form.whatsapp_number || this.form.whatsapp_number === "" ? null : this.form.whatsapp_number,
                        "identity_expired_date": !this.form.identity_expired_date || this.form.identity_expired_date === "" ? null : this.form.identity_expired_date,
                        "identity_valid_forever": !this.form.identity_valid_forever || this.form.identity_valid_forever === "" ? false : this.form.identity_valid_forever,
                        "religion": !this.form.religion || this.form.religion === "" ? null : this.form.religion,
                        "blood_type": !this.form.blood_type || this.form.blood_type === "" ? null : this.form.blood_type,
                        "marital_status": !this.form.marital_status || this.form.marital_status === "" ? null : this.form.marital_status,
                        "children_amount": !this.form.children_amount || this.form.children_amount === "" ? null : this.form.children_amount,
                        "domicile_address": !this.form.domicile_address || this.form.domicile_address === "" ? null : this.form.domicile_address 
                    }
                }
            };

            await this.patchProfile({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        },

        validateData() {
            this.errorsD = {};
            let isValidated = true;
            
            if (!this.form.identity_number) {
                this.errorsD["identity_number"] = "nomor ktp harus diisi";
                isValidated = false;
            }
            
            if (this.form.identity_number && !/^\d*$/.test(this.form.identity_number)) {
                this.errorsD["identity_number"] = "nomor ktp tidak valid";
                isValidated = false;
            }
            
            if (this.form.fullname_ktp && !/^[a-zA-Z ]*$/.test(this.form.fullname_ktp)) {
                this.errorsD["fullname_ktp"] = "nama ktp tidak valid";
                isValidated = false;
            }
            
            if (this.form.whatsapp_number && !/^\d*$/.test(this.form.whatsapp_number)) {
                this.errorsD["whatsapp_number"] = "nomor whatsapp tidak valid";
                isValidated = false;
            }
            
            if (!this.form.birthplace_city_id) {
                this.errorsD["birthplace_city_id"] = "tempat lahir harus diisi";
                isValidated = false;
            }
            
            if (!this.form.birthdate) {
                this.errorsD["birthdate"] = "tanggal lahir harus diisi";
                isValidated = false;
            }
            
            if (!this.form.gender) {
                this.errorsD["gender"] = "jenis kelamin harus diisi";
                isValidated = false;
            }
            
            if (!this.form.address) {
                this.errorsD["address"] = "alamat sesuai ktp harus diisi";
                isValidated = false;
            }
            
            if (this.form.children_amount && !/^\d*$/.test(this.form.children_amount)) {
                this.errorsD["children_amount"] = "jumlah anak tidak valid";
                isValidated = false;
            }

            return isValidated ? true : false;
        },
        
        async getCityOptions(query){
            this.isLoading = true;
            this.cities = await this.getCities({name: query});
            this.isLoading = false;
        },
    }
};
</script>

<style scoped></style>
